import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Connection,
  ConnectionLineType,
  ConnectionMode,
  Edge,
  MiniMap,
  Node,
  ReactFlowProvider,
  updateEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStore,
} from "reactflow";
import { UpdateFlowChart, StepType, EdgeUpdateType } from "../../common/communication.base";
import { NclFlowChart } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import EdgeFloating from "./EdgeFloating";
import EdgeStraight from "./EdgeStraight";
import NodeCaption from "./NodeCaption";
import NodeEdge from "./NodeEdge";
import NodeStep from "./NodeStep";
import Menubar from "./Menubar";
import "reactflow/dist/style.css";
import "./FlowChart.scss";
import {
  SvgStepStart,
  SvgStepEnd,
  SvgStepTransfer,
  SvgStepAcknowledge,
  SvgStepCase,
  SvgStepDistrib,
  SvgStepSubModel,
  SvgSelect,
  SvgCopy,
  SvgDelete,
  SvgPaste,
} from "./SvgStep";
import { __ } from "../../appcontext";
import SelectingArea from "./SelectingArea";
import K2Img from "../Image/K2Img";
import ZeroPoint from "./ZeroPoint";

const nodeTypes = {
  Step: NodeStep,
  Caption: NodeCaption,
  Edge: NodeEdge,
  SelectingArea: SelectingArea,
  ZeroPoint: ZeroPoint,
};

const edgeTypes = {
  Floating: EdgeFloating,
  straight: EdgeStraight,
};

function K2FlowChartWithProvider(props: WithContextPlacementProps) {
  return (
    <ReactFlowProvider>
      <K2FlowChart controlUID={props.controlUID} vrUID={props.vrUID} style={props.style} />
    </ReactFlowProvider>
  );
}

function K2FlowChart(props: WithContextPlacementProps) {
  const [control, data, element] = useServerState<NclFlowChart, UpdateFlowChart, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclFlowChart
  );
  const zoomLevel = useStore((store) => store.transform[2]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [menuActions, setMenuActions] = useState(actions);
  const ctrl = useRef(false);
  const clickTime = useRef(-1);
  const clickDetected = useRef(false);

  const reactFlowInstance = useReactFlow();
  const [selectingArea, setSelectingArea] = useState<Node>(null);
  const isSelecting = useRef<boolean>(false);
  const firstSelectionPosition = useRef<{ x: number; y: number }>(null);
  const { getIntersectingNodes } = useReactFlow();
  const [nodeDragging, setNodeDragging] = useState<boolean>(false);

  const inEdit = useRef<boolean>(data.nodesConnectable);

  let backgroundClick = false;

  useEffect(() => {
    document.addEventListener("keydown", hnadleKeyDown);
    return () => {
      document.removeEventListener("keydown", hnadleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (data.nodes == null || data.edges == null) return;

    const nodes = data.nodes.toJS() as Node[];
    // Nultý bod
    nodes.push(nodeZeroPoint);
    // Výběrová plocha
    if (selectingArea) nodes.push(selectingArea);

    setNodes(nodes);
    setEdges(data.edges.toJS() as Edge[]);

    inEdit.current = data.nodesConnectable;
    if (!data.nodesConnectable) {
      setMenuActions(actions);
    }
  }, [data, selectingArea]);

  const onEdgeUpdate = (oldEdge: Edge, newConnection: Connection) => {
    // console.log("onEdgeUpdate");
    if (oldEdge.source !== newConnection.source && newConnection.source) {
      control.edgeUpdate(oldEdge.id, String(EdgeUpdateType.rejoinSource), newConnection.source);
    }
    if (oldEdge.target !== newConnection.target && newConnection.target) {
      control.edgeUpdate(oldEdge.id, String(EdgeUpdateType.rejoinTarget), newConnection.target);
    }
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  };

  const onEdgeUpdateStart = () => {
    if (data.nodes == null) return;
    const nodes = data.nodes.toJS().map((node: Node) => nodeChangeConnectable(node, true));
    setNodes(nodes);
  };

  const onEdgeUpdateEnd = () => {
    if (data.nodes == null) return;
    const nodes = data.nodes.toJS().map((node: Node) => nodeChangeConnectable(node, false));
    setNodes(nodes);
  };

  const nodeChangeConnectable = (node: Node, state: boolean) => {
    if (node.type === "Step") node.data.isConnectable = state;
    return node;
  };

  function getDataNode(id: string): Node {
    return data.nodes.toJS().find((node: Node) => node.id === id) as Node;
  }

  function getDataEdge(id: string): Edge {
    return data.edges.toJS().find((edge: Edge) => edge.id === id) as Edge;
  }

  const onConnect = useCallback(
    (params: Edge | Connection) => {
      if (params.source == null || params.target == null) return;

      const selectedAction = menuActions.find((action) => action.checked === true);
      if (!selectedAction || ["action0"].includes(selectedAction.id)) return;

      let edgeType: string;
      switch (selectedAction.id) {
        case "action8":
          edgeType = "approve";
          break;
        case "action9":
          edgeType = "reject";
          break;
        default:
          return;
      }

      // console.log("AddLink", params.source, params.target, edgeType);
      control.addLink(params.source, params.target, edgeType);
    },
    [menuActions]
  );

  function updateActions(actions: MenuActions[]) {
    setMenuActions(actions);

    const selectedAction = actions.find((action) => action.checked === true);

    switch (selectedAction?.id) {
      case "action0":
        // console.log("default");
        control.toolBarClick("default");
        break;
      case "action8":
      case "action9":
        // console.log("edge");
        control.toolBarClick("edge");
        break;
      default:
        // console.log("node");
        control.toolBarClick("node");
        break;
    }
  }

  function updateCtrlKey(ctrlKey: boolean) {
    if (ctrlKey) {
      ctrl.current = true;

      return;
    }

    ctrl.current = false;
  }

  function handleNodeClick(e: React.MouseEvent, node: Node) {
    // console.log("NodeClick", node.id);
    if (e.ctrlKey) {
      syncSelected(inEdit.current && !data.Options?.LockControls ? [] : [node.id], e.ctrlKey);
    } else control.nodeClick(node.id);
  }
  function handleNodeContextMenu(e: React.MouseEvent, node: Node) {
    control.nodeContextMenu(node.id);
  }

  function handleSelect(ids: string[]) {
    // console.log("SelectedIDs", ids);
    control.select(ids);
  }

  function syncSelected(IDs: string[] = [], ctrl = false, forgetSelected = false) {
    const selectedIds = new Set(!forgetSelected ? getSelectedIds() : []);
    // console.log(addIDs, removeIDs);
    IDs.forEach((id) => {
      if (!selectedIds.has(id)) {
        selectedIds.add(id);
      } else if (selectedIds.has(id) && ctrl) {
        selectedIds.delete(id);
      }
    });

    handleSelect(Array.from(selectedIds).filter((item) => item.includes("NodeStep")));
  }

  function getSelectedIds() {
    const selectedIds: Array<string> = [];
    nodes.forEach((node) => {
      if (node.selected) selectedIds.push(node.id);
    });
    edges.forEach((edge) => {
      if (edge.selected) selectedIds.push(edge.id);
    });
    return selectedIds;
  }

  function handleNodeDoubleClick(e: React.MouseEvent, node: Node) {
    // console.log("NodeDoubleClick", node.id);
    control.nodeDoubleClick(node.id);
  }

  function handleEdgeClick(e: React.MouseEvent, edge: Edge) {
    let dlbclick: boolean;
    const now: number = Date.now();
    const diff: number = now - clickTime.current;

    if (clickDetected.current && diff < 400) {
      dlbclick = true;
      clickDetected.current = false;
      clickTime.current = 0;
    } else {
      dlbclick = false;
      clickDetected.current = true;
      clickTime.current = Date.now();
    }

    if (!dlbclick) {
      // console.log("EdgeClick", edge.id);
      control.edgeClick(edge.id);
    } else {
      // console.log("EdgeDoubleClick", edge.id);
      control.edgeDoubleClick(edge.id);
    }
  }
  function handleEdgeContextMenu(e: React.MouseEvent, edge: Edge) {
    control.edgeContextMenu(edge.id);
  }

  function handlePanClick() {
    // console.log("PanClick");
    backgroundClick = true;
  }

  function handleBackgroundClick() {
    if (backgroundClick) {
      control.backgroundClick();
      backgroundClick = false;
    }
  }

  function handleNodeDragStart(e: React.MouseEvent, node: Node) {
    setNodeDragging(true);
  }

  function handleNodeDragStop(e: React.MouseEvent, node: Node) {
    // console.log("NodeDragStop", node.id, node.positionAbsolute?.x.toString(), node.positionAbsolute?.y.toString());
    if (node) {
      if (node.position.x !== getDataNode(node.id).position.x || node.position.y !== getDataNode(node.id).position.y) {
        control.nodeDragStop(node.id, node.position.x.toString(), node.position.y.toString());
      }
    }
    setNodeDragging(false);
  }

  function handleClick(e: React.MouseEvent) {
    const selectedAction = menuActions.find((action) => action.checked === true);

    if (!selectedAction || ["action0", "action8", "action9"].includes(selectedAction.id)) {
      handleBackgroundClick();
      return;
    }
    backgroundClick = false;

    if (!ctrl.current) {
      const newActions = menuActions.map((action) => {
        return {
          ...action,
          checked: action.id === "action0" ? true : false,
        };
      });

      setMenuActions(newActions);
    }

    const reactFlowBounds = element.current?.getBoundingClientRect();

    if (!reactFlowBounds) return;

    const x = (e.clientX - reactFlowInstance.getViewport().x - reactFlowBounds.left) / reactFlowInstance.getViewport().zoom;
    const y = (e.clientY - reactFlowInstance.getViewport().y - reactFlowBounds.top) / reactFlowInstance.getViewport().zoom;

    control.addNode(selectedAction.stepType.toString(), x.toString(), y.toString());
  }

  function handleMouseDown(e: React.MouseEvent) {
    if (e.button === 2) {
      const bounds = element.current!.getBoundingClientRect();
      firstSelectionPosition.current = reactFlowInstance.project({
        x: e.clientX - bounds.left,
        y: e.clientY - bounds.top,
      });
      setSelectingArea({
        position: {
          x: firstSelectionPosition.current.x,
          y: firstSelectionPosition.current.y,
        },
        id: "SelectingArea",
        type: "SelectingArea",
        draggable: false,
        data: { width: 1, height: 1 }, // Jinak to funguje zvláštně a vybírá všechny prvky pri pravém kliku na pozadí
      });
      isSelecting.current = true;
    }
  }

  function handleMouseMove(e: React.MouseEvent) {
    if (isSelecting.current) {
      const bounds = element.current!.getBoundingClientRect();
      const relPos = reactFlowInstance.project({
        x: e.clientX - bounds.left,
        y: e.clientY - bounds.top,
      });
      let width = relPos.x - firstSelectionPosition.current.x;
      let height = relPos.y - firstSelectionPosition.current.y;
      const position = { x: firstSelectionPosition.current.x, y: firstSelectionPosition.current.y };

      if (width < 0) {
        position.x = position.x + width;
        width = -width;
      }
      if (height < 0) {
        position.y = position.y + height;
        height = -height;
      }

      setSelectingArea({
        ...selectingArea,
        position: position,
        data: { width: width, height: height },
      });
    }
  }

  function handleMouseUp(e: React.MouseEvent) {
    if (isSelecting.current) {
      const intersections = getIntersectingNodes(selectingArea).map((n) => n.id);
      syncSelected(intersections, e.ctrlKey, !e.ctrlKey);

      isSelecting.current = false;
      firstSelectionPosition.current = null;
      setSelectingArea(null);
    }
  }

  function handleMouseLeave() {
    if (isSelecting.current) {
      isSelecting.current = false;
      firstSelectionPosition.current = null;
      setSelectingArea(null);
    }
  }

  function hnadleKeyDown(e: KeyboardEvent) {
    if (e.ctrlKey && e.code === "KeyC") {
      control.toolBarClick("copy");
    }
    if (inEdit.current) {
      if (e.ctrlKey && e.code === "KeyV") {
        control.toolBarClick("paste");
      }
      if (e.code === "Delete") {
        control.toolBarClick("del");
      }
      if (e.code === "Escape") {
        if (nodeDragging) {
          e.preventDefault();
          e.stopPropagation();
        }
      }
    }
  }
  function handleContextMenu(e: React.MouseEvent) {
    e.preventDefault();
  }

  return (
    <div className={"flc " + (inEdit.current ? "inEdit" : "")} style={StyleHelper(control, props.style)}>
      <Menubar
        actions={menuActions}
        fireActions={fireActions}
        handleFireAction={(action) => control.toolBarClick(action)}
        updateActions={updateActions}
        updateCtrlKey={updateCtrlKey}
        zoomLevel={zoomLevel}
      />
      <div ref={element} className="flc_main">
        <div style={{ width: 0, height: 0 }}>
          <svg>
            <defs>
              <marker
                className="react-flow__arrowhead"
                id="arrowActive"
                markerWidth="12.5"
                markerHeight="12.5"
                viewBox="-10 -10 20 20"
                markerUnits="strokeWidth"
                orient="auto"
                refX="0"
                refY="0"
              >
                <polyline strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" points="-5,-4 0,0 -5,4 -5,-4"></polyline>
              </marker>
              <marker
                className="react-flow__arrowhead"
                id="arrowBack"
                markerWidth="12.5"
                markerHeight="12.5"
                viewBox="-10 -10 20 20"
                markerUnits="strokeWidth"
                orient="auto"
                refX="0"
                refY="0"
              >
                <polyline strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" points="-5,-4 0,0 -5,4 -5,-4"></polyline>
              </marker>
            </defs>
          </svg>
        </div>
        <ReactFlow
          onClick={handleClick}
          //          onDrop={onDrop}
          //onDragOver={onDragOver}
          onNodeClick={handleNodeClick}
          onNodeContextMenu={handleNodeContextMenu}
          onNodeDoubleClick={handleNodeDoubleClick}
          onEdgeClick={handleEdgeClick}
          onEdgeContextMenu={handleEdgeContextMenu}
          onNodeDragStart={handleNodeDragStart}
          onNodeDragStop={handleNodeDragStop}
          // onConnect={handleConnection}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeUpdate={onEdgeUpdate}
          onEdgeUpdateStart={onEdgeUpdateStart}
          onEdgeUpdateEnd={onEdgeUpdateEnd}
          elevateEdgesOnSelect={true}
          onConnect={onConnect}
          panOnDrag={data.panOnDrag}
          // zoomOnScroll={false}
          zoomOnDoubleClick={false}
          nodesDraggable={inEdit.current && !data.Options?.LockControls}
          nodesConnectable={inEdit.current}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          multiSelectionKeyCode={["Control", "Meta"]}
          onContextMenu={handleContextMenu}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
          connectionMode={ConnectionMode.Loose} //allows connecting Source to Source handle
          connectionLineType={ConnectionLineType.Straight}
          snapGrid={[data.Options?.GridGapSize, data.Options?.GridGapSize]}
          snapToGrid={data.Options?.GridEnabled}
        >
          <MiniMap />
          {data.Options?.GridEnabled && data.Options?.ShowGrid && (
            <Background gap={data.Options?.GridGapSize} offset={2} color="#00000010" variant={BackgroundVariant.Lines} />
          )}
        </ReactFlow>
      </div>
    </div>
  );
}

export default K2FlowChartWithProvider;

export interface MenuActions {
  id: string;
  stepType?: StepType;
  name?: string;
  title: string;
  image: JSX.Element;
  checked?: boolean;
}

const actions: MenuActions[] = [
  {
    id: "action0",
    stepType: StepType.itUndefined,
    name: "node",
    title: "Výběr",
    image: <SvgSelect />,
    checked: true,
  },
  {
    id: "action1",
    stepType: StepType.itStart,
    name: "node",
    title: __("flowchartCommencement"),
    image: <SvgStepStart />,
    checked: false,
  },
  {
    id: "action2",
    stepType: StepType.itEnd,
    name: "node",
    title: __("flowchartEnding"),
    image: <SvgStepEnd />,
    checked: false,
  },
  {
    id: "action3",
    stepType: StepType.itTransfer,
    name: "node",
    title: __("flowchartTransformation"),
    image: <SvgStepTransfer />,
    checked: false,
  },
  {
    id: "action4",
    stepType: StepType.itAcknowledge,
    name: "node",
    title: __("flowchartAcknowledge"),
    image: <SvgStepAcknowledge />,
    checked: false,
  },
  {
    id: "action5",
    stepType: StepType.itCase,
    name: "node",
    title: __("flowchartStep"),
    image: <SvgStepCase />,
    checked: false,
  },
  {
    id: "action6",
    stepType: StepType.itDistrib,
    name: "node",
    title: __("flowchartDistrib"),
    image: <SvgStepDistrib />,
    checked: false,
  },
  {
    id: "action7",
    stepType: StepType.itSubModel,
    name: "node",
    title: __("flowchartSubModel"),
    image: <SvgStepSubModel />,
    checked: false,
  },
  {
    id: "action8",
    stepType: StepType.itUndefined,
    name: "edge",
    title: __("flowchartEdge"),
    image: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
      </svg>
    ),
    checked: false,
  },
  {
    id: "action9",
    stepType: StepType.itUndefined,
    name: "edge",
    title: __("flowchartEdgeSecond"),
    image: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
      </svg>
    ),
    checked: false,
  },
];

const fireActions: MenuActions[] = [
  {
    id: "copy",
    title: "Kopírovat (Ctrl + C)",
    image: <SvgCopy />,
  },
  {
    id: "paste",
    title: "Vložit (Ctrl + V)",
    image: <SvgPaste />,
  },
  {
    id: "del",
    title: "Smazat (Del)",
    image: <SvgDelete />,
  },
];
const nodeZeroPoint = {
  position: {
    x: -1,
    y: -1,
  },
  id: "ZeroPoint",
  type: "ZeroPoint",
  draggable: false,
  data: {},
};
