import React from "react";
import { Context } from "../../appcontext";
import { ElementHtmlAttributes, getAttributes, isKeyOrDigit } from "../../common/common";
import { UpdateInnerDataGrid, UpdateControl, GridOperations, ColumnProportionEm, OrderByItem, FocusData } from "../../common/communication.base";
import { DataGridListener, UFOverAlign, NclInnerDataGrid, NclInput, NclSplitterPanel, NclDataGrid } from "../../common/components.ncl";
import { ViewRealizerManager, RealizerOperations } from "../../viewrealizer";
import { AcquireControl, WithContextPlacementProps, StyleHelper, K2ComponentState } from "../k2hoc";
import ReactResizeDetector from "react-resize-detector";
import K2ColGroup from "./K2ColGroup";
import K2DGHeader from "./K2DGHeader";
import { K2Rows } from "./K2Rows";
import { K2Row } from "./K2Row";
import css from "./DataGrid.scss";
import K2AggregationPanel from "./K2AggregationPanel";
import resolveContextMenu from "../../utils/resolveContextMenu";

interface DataGridProps extends WithContextPlacementProps {
  focusData: FocusData;
}

interface InnerDataGridState extends K2ComponentState<UpdateInnerDataGrid> {
  Width: number;
  Height: number;
  clientWidthDiffs?: Array<number>;
  xDragPos: number;
  dragging: boolean;
  scrollLeft: number;
}

export class K2InnerDataGrid extends React.PureComponent<DataGridProps, InnerDataGridState> implements DataGridListener, UFOverAlign {
  private rows: K2Rows;
  static displayName = `K2InnerDataGrid`;
  private control: NclInnerDataGrid;
  private requestSetAsActive: boolean;
  private columns: DOMRect[];
  private draggedColumnIndex: number;
  private hoveredColumnIndex: number;
  private scrollDiv = React.createRef<HTMLDivElement>();
  private table: HTMLTableElement;
  private content: HTMLDivElement;
  private firstUpdate = false;
  private scrollToRequest: { rowNdx: number; firstVisible: boolean };
  private lastResizeTime: number = undefined;
  private resizeTimeoutH: NodeJS.Timer;
  private lastTouchX: number;
  private lastTouchY: number;
  private element = React.createRef<HTMLDivElement>();

  constructor(props: DataGridProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInnerDataGrid;
    }) as NclInnerDataGrid;

    this.state = {
      data: this.control.init(this) as UpdateInnerDataGrid,
      Width: 0,
      Height: 0,
      vcxVersion: -1,
      xDragPos: undefined,
      dragging: false,
      scrollLeft: 0,
    };
  }

  getOverRect(): DOMRect {
    if (this.rows) {
      const row = this.rows.getRow(this.control.viewRowNdx);
      if (row) return row.getOverRect(this.control.ColNdx);
    }

    return null;
  }

  updateState(state: UpdateControl) {
    if (state instanceof UpdateInnerDataGrid) {
      if (state.ComputedColumnsVersion !== state.ColumnsVersion && this.state.Width > 0) {
        this.control.reCalculateColumnWidths(this.state.Width);
        this.setMaxRowCount(this.state.Height);
        return; // recalculate columns width and call update
      }

      if (state.ColumnsVersion !== this.state.data.ColumnsVersion) {
        this.setMaxRowCount(this.state.Height);
      }
    }

    this.setState((prevState: InnerDataGridState) => {
      let diffs = prevState.clientWidthDiffs;
      if (state instanceof UpdateInnerDataGrid) {
        if (state.CanEditItems !== prevState.data.CanEditItems) {
          this.updateCanEditItems(state.CanEditItems);
        }
        if (state.ColumnsVersion !== prevState.data.ColumnsVersion) {
          diffs = null;
        }

        return { data: state as UpdateInnerDataGrid, clientWidthDiffs: diffs };
      }
    });
  }

  private updateCanEditItems(value: boolean) {
    if (this.rows) {
      const row = this.rows.getRow(this.control.viewRowNdx);
      if (row) {
        row.updateInEditMode(value);
      }
    }
  }

  updatePosition(oldRow: number, newRow: number, oldCol: number, newCol: number) {
    if (this.rows && (oldRow !== newRow || oldCol !== newCol)) {
      let ar: K2Row;
      if (oldRow !== newRow) {
        ar = this.rows.getRow(oldRow);
        if (ar) {
          ar.updateSelected(false, oldCol, newCol);
        }
      }
      ar = this.rows.getRow(newRow);
      if (ar) {
        ar.updateSelected(true, oldCol, newCol);
        if (this.requestSetAsActive) {
          ar.setAsActive(true);
          this.requestSetAsActive = false;
        }
      }

      if (this.requestSetAsActive) {
        this.element.current?.focus();
        this.requestSetAsActive = false;
      }
    }
  }

  componentDidUpdate(prevProps: WithContextPlacementProps, prevState: InnerDataGridState) {
    this.setAsActive(this.props.focusData.isFocused);

    if (prevState.Height != this.state.Height && this.scrollToRequest) {
      this.scrollTo(this.scrollToRequest.rowNdx, this.scrollToRequest.firstVisible);
      this.scrollToRequest = undefined;
      this.ignoreMove = false;
    }

    // When the scroll horizontal appears inside the resizeDetector
    if (this.state.clientWidthDiffs !== prevState.clientWidthDiffs) {
      this.onResize(this.content.clientWidth, this.content.clientHeight);
    }

    if (!this.state.clientWidthDiffs && prevState.Width != this.state.Width) {
      this.control.reCalculateColumnWidths(this.state.Width);
      this.setMaxRowCount(this.state.Height);
      return;
    }

    // Set top and current index after grid first rendered
    if (this.firstUpdate === true) {
      this.scrollTo(this.control.topNdxOfView, true);
      const cmp = this.control.isInVisibleView(this.control.viewRowNdx);
      if (cmp === 0) {
        this.updatePosition(-1, this.control.viewRowNdx, -1, this.control.ColNdx);
      } else {
        if (this.control.moveViewTo(this.control.viewRowNdx, cmp)) {
          // scroll on selected row in view
          const ar = this.scrollTo(this.control.viewRowNdx, cmp < 0);
          if (ar) {
            ar.updateSelected(true, -1, this.control.ColNdx);
          }
        }
      }
      this.firstUpdate = false;
      this.ignoreMove = false;
    }

    // Show current in view after (current)position changed
    if (prevState.data.Position !== this.state.data.Position) {
      if (!this.ignoreMove) {
        const cmp = this.control.isInVisibleView(this.control.viewRowNdx);
        if (cmp !== 0) {
          this.control.moveViewTo(this.control.viewRowNdx, cmp);
          this.scrollTo(this.control.viewRowNdx, cmp < 0);
        }
      }
    }

    // Set position after Cache or Data update
    if (prevState.data.DataVersion != this.state.data.DataVersion) {
      this.ignoreMove = false;
      this.onResize(this.content.clientWidth, this.content.clientHeight);

      this.scrollTo(this.control.topNdxOfView, true);
      if (this.control.viewRowNdx >= 0 && this.control.viewRowNdx < this.control.data.length) {
        this.updatePosition(-1, this.control.viewRowNdx, -1, this.control.ColNdx);
      }
    }
  }

  private setMaxRowCount(height: number) {
    if (this.control.getRowHeight() > 0 && height > 0) {
      const maxRowCount: number = height / this.control.getRowHeight();
      this.control.Container.setMaxRowCount(Math.floor(maxRowCount));
    }
  }

  componentDidMount() {
    if (this.control) {
      this.firstUpdate = true;
    }

    // Disabling IOS multiple finger gesture
    if (this.iOS() && this.isMobileAndSplitterVertical()) {
      document.body.style.touchAction = "none";
    }

    this.element.current.addEventListener("wheel", this.handleNonPassiveWheel, { passive: false });
    resolveContextMenu(this.content, this.handleContextMenu);
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  setAsActive(isActive: boolean) {
    if (isActive) {
      if (this.rows) {
        const cmp = this.control.isInVisibleView(this.control.viewRowNdx);
        if (cmp === 0) {
          const ar = this.rows.getRow(this.control.viewRowNdx);
          if (ar) {
            ar.setAsActive(isActive);
            return;
          } else {
            this.element.current?.focus();
          }
        } else {
          this.element.current?.focus();
        }
      } else {
        this.requestSetAsActive = true;
      }
    } else {
      this.requestSetAsActive = false;
    }
  }

  clearResizeTimeout() {
    if (this.resizeTimeoutH) {
      clearTimeout(this.resizeTimeoutH);
      this.resizeTimeoutH = undefined;
    }
  }

  componentWillUnmount() {
    this.element.current.removeEventListener("wheel", this.handleNonPassiveWheel);
    this.clearResizeTimeout();
    this.control.willUnMount(true);
    this.control = null;
  }

  private calcTotalWidth(): number {
    if ((!this.state.clientWidthDiffs || !this.state.data.AutoAdjustWidth) && !this.state.data.ColumnsProportion) return null;
    let result = 0;
    this.state.data.ColumnsProportion.map((value, i) => {
      result += value.Width;
    });

    if (isNaN(result)) return 0;

    if (this.state.clientWidthDiffs) {
      this.state.clientWidthDiffs.map((value) => {
        result += value;
      });
    }

    return result;
  }

  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  handleDragEnter = (e: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (!this.state.dragging) return;

    let xPos = 0;

    if (e.nativeEvent instanceof DragEvent) {
      xPos = (e as React.DragEvent).clientX;
    } else {
      xPos = (e as React.TouchEvent).touches[0].clientX;
    }

    const offsetLeft = e.currentTarget.getBoundingClientRect().left;
    this.columns?.map((column, index) => {
      if (xPos < column.right && index < this.state.data.FixedColumnsCount) return;
      if (xPos > column.left && xPos < column.right) {
        if (this.draggedColumnIndex >= index) {
          this.setState({ xDragPos: column.left - offsetLeft + this.scrollDiv.current.scrollLeft });
          this.hoveredColumnIndex = index;
        } else {
          this.setState({ xDragPos: column.right - offsetLeft + this.scrollDiv.current.scrollLeft });
          this.hoveredColumnIndex = index;
        }
      }
    });
  };

  handleDrop = () => {
    if (!this.state.dragging) return;

    if (this.draggedColumnIndex === this.hoveredColumnIndex) {
      this.setState({ dragging: false });
      return;
    }

    if (this.draggedColumnIndex == undefined) return;

    this.control.columnMove(this.draggedColumnIndex, this.hoveredColumnIndex, true);
    this.setState({ dragging: false });
    this.draggedColumnIndex = null;
  };

  setColumns = (options: { sizes: DOMRect[]; draggedColumnIndex: number }) => {
    this.columns = options.sizes;
    this.draggedColumnIndex = options.draggedColumnIndex;
    this.columns.map((column, index) => {
      if (this.draggedColumnIndex === index) {
        this.hoveredColumnIndex = index;
      }
    });
    this.setState({ dragging: true });
  };

  deleteColumn = () => {
    this.control.columnMove(this.draggedColumnIndex, -1, true);
    this.setState({ dragging: false });
  };

  getColumnsWidthsWithDiffs = (columns: ColumnProportionEm[]) => {
    let diff = 0;

    return columns.map((column, i) => {
      if (this.state.clientWidthDiffs && i < this.state.clientWidthDiffs.length) {
        diff = this.state.clientWidthDiffs[i];
      }

      return column.Width + diff;
    });
  };

  getFixedColumnOffset = (columnsWidths: number[], index: number, fixed: boolean): number => {
    let offset = 0;
    if (columnsWidths && fixed) {
      for (let i = 0; i < index; i++) {
        offset += columnsWidths[i];
      }
    }

    return offset;
  };

  isTotalWidthNull = (width: number) => {
    return width === 0;
  };

  render() {
    const columnsProportion: Array<ColumnProportionEm> = this.state.data.ColumnsProportion;

    if (!columnsProportion) return null;

    const addAttributes: ElementHtmlAttributes = getAttributes(this.state.data);
    const width = this.calcTotalWidth();
    const columnsWidthsWithDiffs: number[] = this.getColumnsWidthsWithDiffs(columnsProportion);

    return (
      <div
        ref={this.element}
        style={StyleHelper(this.control, this.props.style)}
        className="dg_inner"
        onFocus={this.handleFocus}
        {...addAttributes}
        onDragOver={this.handleDragOver}
        onDragEnter={this.handleDragEnter}
        onDrop={this.handleDrop}
        onTouchMove={this.handleDragEnter}
        onTouchEnd={this.handleDrop}
        onKeyDown={this.handleKeyDown}
        onWheel={this.handleWheel}
      >
        <div className={`${css.dg_table}${this.state.data.CanEditItems ? ` ${css.dg_table_edit}` : ""}`} ref={this.scrollDiv}>
          {this.state.dragging && this.hoveredColumnIndex !== undefined && <div className={css.dg_drag_handle} style={{ left: this.state.xDragPos }}></div>}

          {!this.control.isHideHeading() && (
            <K2DGHeader
              quickFilter={this.control.QuickFilter}
              key={this.control.Ncl.ControlUID + "_header"}
              orderBy={this.state.data.OrderBy.toJS() as OrderByItem[]}
              columnsProportion={this.isTotalWidthNull(width) ? [] : columnsProportion}
              fixedColumnsCount={this.state.data.FixedColumnsCount}
              columnsWidths={columnsWidthsWithDiffs}
              columnsVersion={this.state.data.ColumnsVersion}
              vcx={this.control.VCX}
              onColumnContextMenu={this.handleColumnContextMenu}
              onColumnClick={this.handleHeaderColumnClick}
              onColumnResize={this.handleColumnResize}
              setColumns={this.setColumns}
              deleteColumn={this.deleteColumn}
              getFixedColumnOffset={this.getFixedColumnOffset}
              width={width}
              scrollLeft={this.state.scrollLeft}
              isDraggable={this.control.Ncl.CanColumnDrag}
              isResizable={this.control.Container instanceof NclDataGrid}
            />
          )}
          <div className="dg_rows_wrap">
            <ReactResizeDetector handleHeight handleWidth refreshMode="debounce" refreshRate={0} onResize={this.onResize} />
            <div
              ref={(ref) => {
                this.content = ref;
              }}
              className={`dg_rows${this.control.isCacheEnabled() ? " dg_rows_scrollable" : ""}`}
              onScroll={this.handleTableScroll}
              onTouchStartCapture={(e) => this.handleTableTouch(e, false)}
              onTouchEndCapture={(e) => this.handleTableTouch(e, true)}
              onTouchMove={this.handleTableTouchMove}
              onClick={this.handleClick}
            >
              <table
                ref={(ref) => (this.table = ref)}
                className={`${css.dg_body}${this.state.data.CanEditItems ? ` ${css.dg_body_edit}` : ""}`}
                style={{ width: width + "px" }}
              >
                {this.state.data.ColumnsProportion && (
                  <K2ColGroup
                    columnsProportion={this.state.data.ColumnsProportion}
                    fixedColumnsCount={this.state.data.FixedColumnsCount}
                    columnsWidths={columnsWidthsWithDiffs}
                    columnsVersion={this.state.data.ColumnsVersion}
                  />
                )}

                <K2Rows
                  key={this.control.Ncl.ControlUID + "_rows"}
                  rowHeightMultiplier={this.control.getRowHeightMultiplier()}
                  inEditMode={false /*this.control.InEditMode*/}
                  rows={this.isTotalWidthNull(width) ? [] : this.control.data}
                  dataVersion={this.state.data.DataVersion}
                  ref={(rows) => {
                    this.rows = rows as K2Rows;
                  }}
                  vcx={this.control.VCX}
                  onColumnContextMenu={this.handleColumnContextMenu}
                  onColumnClick={this.handleColumnClick}
                  columnsProportion={columnsProportion}
                  fixedColumnsCount={this.state.data.FixedColumnsCount}
                  columnsWidths={columnsWidthsWithDiffs}
                  columnsVersion={this.state.data.ColumnsVersion}
                  getFixedColumnOffset={this.getFixedColumnOffset}
                  rowHeight={this.control.getRowHeight()}
                  execute={this.control.executeCommandByNumber}
                />
              </table>
            </div>
          </div>
          {this.control.Container.AggregationPanel && (
            <K2AggregationPanel
              controlUID={this.control.Container.AggregationPanel.MetaData.ControlUID}
              vrUID={this.control.getRealizerUID()}
              width={width}
              getFixedColumnOffset={this.getFixedColumnOffset}
              columnsProportion={columnsProportion}
              fixedColumnsCount={this.state.data.FixedColumnsCount}
              columnsWidths={columnsWidthsWithDiffs}
              columnsVersion={this.state.data.ColumnsVersion}
              scrollLeft={this.state.scrollLeft}
            />
          )}
          {/* {this.getNoDataWarning()} */}
        </div>
      </div>
    );
  }

  private isMobileAndSplitterVertical = () => {
    const isMobile = window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0];
    if (isMobile) {
      if (
        (this.control.Container.Parent instanceof NclSplitterPanel && this.control.Container.Parent.isSplitterVertical()) ||
        (this.control.Container.Parent.Parent instanceof NclSplitterPanel && this.control.Container.Parent.Parent.isSplitterVertical()) ||
        (this.control.Container.Parent.Parent.Parent instanceof NclSplitterPanel && this.control.Container.Parent.Parent.Parent.isSplitterVertical())
      ) {
        return true;
      }
    }
    return false;
  };

  private handleTableTouch = (e: React.TouchEvent<HTMLDivElement>, isEnd: boolean) => {
    if (e.currentTarget) {
      if (this.content) {
        if (e.touches.length > 0) {
          this.lastTouchX = e.touches[0].clientX;
          this.lastTouchY = e.touches[0].clientY;
        }

        if (this.isMobileAndSplitterVertical()) {
          if (e.touches.length === 2) {
            this.mobileActiveTwoFingers(e.target as HTMLDivElement);
            return;
          } else {
            if (!isEnd) {
              e.preventDefault();
              this.mobileActiveOneFinger(e.target as HTMLDivElement);
            }
            return;
          }
        }
      }
    }
  };

  private iOS() {
    return (
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }
  private isFirefox() {
    return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  }

  /** SplitterMobileHorizontal two fingers  */
  private mobileActiveTwoFingers = (e: HTMLDivElement) => {
    if (!(e instanceof HTMLElement)) return;
    const splitter = e.closest(".splitter_mobile_horizontall");

    if (!(splitter instanceof HTMLElement)) return;
    splitter.style.overflow = "hidden";

    // Grid item container
    if (this.iOS() || this.isFirefox()) {
      this.content.style.overflow = "hidden";
      this.content.style.touchAction = "none";
    } else {
      this.content.style.overflow = "auto";
    }
  };

  /** SplitterMobileHorizontal one finger */
  private mobileActiveOneFinger = (e: HTMLDivElement) => {
    if (!(e instanceof HTMLElement)) return;
    const splitter = e.closest(".splitter_mobile_horizontall");

    if (!(splitter instanceof HTMLElement)) return;
    splitter.style.overflow = "auto";

    // Grid item container
    this.content.style.overflow = "hidden";
    if (this.iOS() || this.isFirefox()) {
      this.content.style.touchAction = "inherit";
    }
  };

  private handleTableTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const deltaX = this.lastTouchX - e.touches[0].clientX;
    const deltaY = this.lastTouchY - e.touches[0].clientY;

    this.lastTouchX = e.touches[0].clientX;
    this.lastTouchY = e.touches[0].clientY;

    if (this.ignoreMove) return;

    if (this.iOS() || this.isFirefox()) {
      if (this.isMobileAndSplitterVertical()) {
        if (e.touches.length === 2) {
          this.content.scrollLeft = this.content.scrollLeft + deltaX;
          this.content.scrollTop = this.content.scrollTop + deltaY;
        }
      }
    }

    // Indication of movement out for manual update
    if (this.content.scrollTop === 0 && deltaY < 0) {
      this.handleInvokeAction(GridOperations.prior, true);
      return;
    }
    if (Math.ceil(this.content.scrollTop + this.content.offsetHeight) >= this.content.scrollHeight && deltaY > 0) {
      this.handleInvokeAction(GridOperations.next, true);
      return;
    }
  };

  /** Moving in DataCache */
  private handleTableScroll = (e: React.UIEvent) => {
    this.setState({ scrollLeft: e.currentTarget.scrollLeft });

    const topNdxOfView = Math.floor(this.getTopRowOfView());

    if (this.control.topNdxOfView !== topNdxOfView) {
      if (topNdxOfView < this.control.topNdxOfView) {
        this.control.setTopNdxOfView(topNdxOfView);
        this.handleInvokeAction(GridOperations.prior);
      } else {
        this.control.setTopNdxOfView(topNdxOfView);
        this.handleInvokeAction(GridOperations.next);
      }
    }
  };

  private handleInvokeAction = (op: GridOperations, ignoreBlock = false) => {
    if (this.control.isCacheEnabled()) {
      this.moveTo(op, ignoreBlock);
      return;
    }

    this.control.invokeAction(op);
  };

  private ignoreMove = false;
  private moveTo(op: GridOperations, ignoreBlock: boolean): void {
    if (Context.getApplication().isBusy() || this.ignoreMove === true) return;
    if (this.table) {
      /** If there is UPDATEZONE_LENGTH and less left to start */
      const moveUp = Math.floor(this.getTopRowOfView()) <= this.control.UPDATEZONE_LENGTH;
      /** If there is UPDATEZONE_LENGTH and less left until the end */
      const moveDown = Math.floor(this.getRowsCount() - this.getBottomRowOfView()) <= this.control.UPDATEZONE_LENGTH;

      if (moveUp || moveDown) {
        this.ignoreMove = true;
        let result;

        if (moveDown && op === GridOperations.next) result = this.control.moveView(GridOperations.next, ignoreBlock);
        else if (moveUp && op === GridOperations.prior) result = this.control.moveView(GridOperations.prior, ignoreBlock);
        else this.ignoreMove = false;

        if (result === true) {
          this.ignoreMove = false;
        } else if (result === false) {
          this.ignoreMove = false;
        }
      }
    }
  }

  private getTopRowOfView(): number {
    const value = this.content.scrollTop / this.control.getRowHeight();
    if (value < 0) return 0;
    return value;
  }
  private getBottomRowOfView(): number {
    return (this.content.scrollTop + this.content.offsetHeight) / this.control.getRowHeight();
  }

  private getRowsCount(): number {
    return this.content.scrollHeight / this.control.getRowHeight();
  }

  /** Setting the position in CachePage after updating data */
  private scrollTo(ndx: number, firstVisible: boolean): K2Row {
    const ar = this.rows.getRow(Math.floor(ndx));

    if (ar) {
      if (this.state.Height <= 0) {
        this.scrollToRequest = { rowNdx: ndx, firstVisible: firstVisible };
      } else {
        const content = ar.getContent();
        const viewDiff = (ndx % 1) * this.control.getRowHeight();
        let adjustScroll = 0;

        /** If selected element isn't topScroll */
        if (this.control.viewRowNdx !== this.control.topNdxOfView) {
          /** It scrolls to an element that may not be fully visible yet, hence this minor correction */
          adjustScroll = this.content.scrollTop % this.control.getRowHeight();
        }

        // Fix for IOS scrollTop position wasn't set immediately
        if (this.iOS()) {
          this.content.style.overflow = "hidden";
        }
        if (firstVisible) {
          this.content.scrollTop = content.offsetTop + viewDiff + adjustScroll;
        } else {
          this.content.scrollTop = content.offsetTop - this.content.clientHeight + content.offsetHeight + viewDiff;
        }

        // Fix for IOS scrollTop position wasn't set immediately
        if (this.iOS()) {
          requestAnimationFrame(() => {
            this.content.style.overflow = "auto";
          });
        }
      }
      return ar;
    }
    return undefined;
  }

  private handleColumnContextMenu = (rowIndex: number, colIndex: number) => {
    this.control.contextMenu(rowIndex, colIndex);
  };

  private handleContextMenu = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.control.contextMenu(-1, -1);
  };

  private handleHeaderColumnClick = (rowIndex: number, colIndex: number, clickCount: number, shiftKey: boolean) => {
    this.control.Container.changeSortBy(colIndex, shiftKey);
  };

  private handleColumnClick = (rowIndex: number, colIndex: number, clickCount: number, shiftKey: boolean, ctrlKey: boolean) => {
    this.control.click(rowIndex, colIndex, clickCount, shiftKey, ctrlKey);
  };

  // Pro doubleClick mimo záznamy přesměruje klik na aktuálně vybraný
  private handleClick = () => {
    if (this.rows) {
      const row = this.rows.getRow(this.control.viewRowNdx);
      if (row) {
        const cont = row.getColumn(this.control.ColNdx || 0);
        if (cont && cont.getElement()) {
          cont.getElement().click();
        }
      }
    }
  };

  private handleColumnResize = (colIndex: number, diffX: number, isCompleted: boolean) => {
    let diffs: Array<number>;
    if (isCompleted) {
      if (this.state.data.ColumnsProportion && colIndex < this.state.data.ColumnsProportion.length) {
        if (this.state.clientWidthDiffs) {
          diffs = [...this.state.clientWidthDiffs];
        } else {
          diffs = [];
          this.state.data.ColumnsProportion.map((value, i) => {
            diffs[i] = 0;
          });
        }
      }
      if (diffs) {
        diffs[colIndex] += diffX;
        if (this.state.data.ColumnsProportion[colIndex].Width + diffs[colIndex] <= 10) return; //check minimum
        this.control.setColumnsWidth(diffs, this.state.data.ColumnsProportion);
        this.setState({ clientWidthDiffs: diffs });
      }
    }
  };

  private handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    this.control.Container.setActiveControlRequested();
    e.stopPropagation();
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === "NumpadSubtract" || e.code === "NumpadAdd" || e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
      // e.target instanceof HTMLInputElement - pokud pisu do QuickFilteru, nefocusuj Locator
      return;
    }

    if (e.key === "ArrowUp") {
      this.control.invokeAction(GridOperations.prior);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    if (e.key === "ArrowDown") {
      if (e.shiftKey) return false;

      this.control.invokeAction(GridOperations.next);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    if (e.key === "ArrowLeft" && this.control.ColNdx - 1 >= 0) {
      this.control.click(undefined, this.control.ColNdx - 1, 1);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    if (e.key === "ArrowRight" && this.state.data.ColumnsProportion && this.control.ColNdx + 1 < this.state.data.ColumnsProportion.length) {
      this.control.click(undefined, this.control.ColNdx + 1, 1);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    if (e.key === "PageDown") {
      this.control.invokeAction(GridOperations.nextPage);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    if (e.key === "PageUp") {
      this.control.invokeAction(GridOperations.priorPage);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    if (e.ctrlKey && e.key === "Home") {
      this.control.invokeAction(GridOperations.firstPage);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    if (e.ctrlKey && e.key === "End") {
      this.control.invokeAction(GridOperations.lastPage);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    if (e.ctrlKey && e.key === "c") {
      if (this.control.data && this.control.viewRowNdx < this.control.data.length) {
        const row = this.control.data[this.control.viewRowNdx];
        if (row && row.Columns && this.control.ColNdx < row.Columns.length) {
          const text = row.Columns[this.control.ColNdx]?.Text;

          if (text) {
            this.control.copy(text);
          }
        }
      }
    }

    if (e.key === "Backspace" && this.control.Container.Header && this.control.Container.Header.LocatorPanel) {
      //backspace
      const locator: NclInput = this.control.Container.Header.LocatorPanel.getLastLocatrInput();
      if (locator) {
        locator.setAsActiveControl();
        e.stopPropagation();
      }
    }

    if (!e.altKey && !e.shiftKey && !e.ctrlKey && isKeyOrDigit(e.key) && this.control.LastVisibleLocatorPanel) {
      const locator: NclInput = this.control.LastVisibleLocatorPanel.getLastLocatrInput();
      if (locator) {
        locator.setAsActiveControl();
        e.stopPropagation();
      }
    }
  };

  private handleWheel = (e: React.WheelEvent) => {
    if (e.shiftKey) return;

    if (e.deltaY > 0) {
      this.handleInvokeAction(GridOperations.next);
    } else {
      this.handleInvokeAction(GridOperations.prior);
    }
  };

  private isContentScrollable(): boolean {
    if (!this.content) return true;
    return this.content.scrollHeight > this.content.clientHeight;
  }

  private handleNonPassiveWheel = (e: WheelEvent) => {
    if (this.isContentScrollable() || this.control.data.length <= 0) return;
    e.preventDefault(); // Zakáže scrolling parenta pokud je cursor na gridu
  };

  private onResize = (width: number, height: number) => {
    height = this.content.clientHeight;
    width = this.content.clientWidth;

    this.clearResizeTimeout();

    const time = new Date().getTime() - this.lastResizeTime;
    const defTimeout = 500;

    if (this.lastResizeTime && time < defTimeout) {
      const __this = this;
      this.resizeTimeoutH = setTimeout(() => {
        this.resizeTimeoutH = undefined;
        __this.onResize(width, height);
      }, defTimeout - time);

      return;
    }

    if ((height > 0 || width > 0) && (width != this.state.Width || height != this.state.Height)) {
      if (height != this.state.Height) {
        if (this.control.getRowHeight() > 0) {
          this.lastResizeTime = new Date().getTime();
          this.setMaxRowCount(height);
        } else {
          const vr = ViewRealizerManager.getViewRealizer(this.control.getRealizerUID()); //if don't know height of row, must call update for columns
          if (vr) vr.sendRequest(RealizerOperations.Update);
        }
      }
      // if (width != this.state.Width) {
      //   //scroll to selected cell
      //   this.getOverRect();
      // }
      this.setState({ Width: width, Height: height });
    }
  };
}
