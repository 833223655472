import React from "react";
import { UpdateTabControl, UpdateControl, TBehaviorTypeByDevice } from "../../common/communication.base";
import { NclMultiContent, NclVRTabControl } from "../../common/components.ncl";
import { AppContext } from "../../context";
import { GenerateControl } from "../K2GenerateControl";
import { WithContextPlacementProps, K2ComponentState, AcquireControl, StyleHelper } from "../k2hoc";
import K2Tab from "../TabControl/K2Tab";
import { Context } from "../../appcontext";
import K2MobileStaticTabs from "./K2MobileStaticTabs";

export class K2MultiContent extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UpdateTabControl>> {
  static displayName = `K2MultiContent`;
  private control: NclMultiContent;
  static contextType = AppContext;
  declare context: React.ContextType<typeof AppContext>;
  private element: HTMLDivElement;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclMultiContent;
    }) as NclMultiContent;
    this.state = { data: this.control.init(this) as UpdateTabControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateTabControl>) => {
      return { data: state as UpdateTabControl };
    });

    this.context?.update();
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  showMobileVRTabs(): boolean {
    return this.control.Parent.Parent.Parent instanceof NclVRTabControl && Context.DeviceInfo.StyleOfModalWindowShow === TBehaviorTypeByDevice.btbdMobile;
  }

  render() {
    return (
      <>
        <div style={StyleHelper(this.control, this.props.style)} ref={(ref) => (this.element = ref)}>
          {this.control.Pages.map((control) => {
            if (control.Content.MetaData.ControlUID == this.state.data.CurrentPage) {
              return GenerateControl(control.Content, { flex: "1 1 auto" });
            }
          })}
        </div>
        {this.showMobileVRTabs() && <K2MobileStaticTabs control={this.control} currentPage={this.state.data.CurrentPage} />}
      </>
    );
  }
}
