import React, { useEffect, useRef } from "react";
import { cJSonFunctionLoadPreviewData, UpdateFilePreview } from "../../common/communication.base";
import { NclFilePreview } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2Img from "../Image/K2Img";
import { getDenyImage } from "../Image/K2Image";
import K2TruncateText from "../Text/K2TruncateText";
import K2FileViewer from "./K2FileViewer";
import css from "./FilePreview.scss";
import { Context, __ } from "../../appcontext";
import { K2FullScreenViewer } from "./K2FullScreenViewer";

const K2FilePreview = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclFilePreview, UpdateFilePreview, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclFilePreview
  );
  let content!: JSX.Element;
  const firstLoad = useRef(true);

  const isOthersSupportedFile = () => {
    if (data.ObjectId) {
      const ext = data.ObjectId.substring(data.ObjectId.length - 3);

      if (ext.toUpperCase() === "PDF") return true;
    }

    return false;
  };

  const isZoomPanAsModal = () => {
    return isFullScreenContent() && !isOthersSupportedFile() && !(control.Ncl.FrgtData.PanAllowed || control.Ncl.FrgtData.ZoomAllowed);
  };

  const handleClick = () => {
    if (isZoomPanAsModal()) {
      if (Context.getApplication()?.fullScreenViewer) {
        Context.getApplication().fullScreenViewer.show(content, data.Title);
      }
    }
  };

  function isFullScreenContent(): boolean {
    return data.ImageDeny !== true && data.IsSupported !== false && data.IsLoaded;
  }

  useEffect(() => {
    if (data.IsSupported && data.ObjectId && (!data.IsLoaded || firstLoad.current)) {
      // Po prvním zobrazení komponenty si může server špatně pamatovat IsLoaded..
      control.appendFunction({ Name: cJSonFunctionLoadPreviewData, Args: [data.IsLoaded && firstLoad.current] }, true);
    }
  }, [data.ObjectId]);

  useEffect(() => {
    firstLoad.current = false;
  }, []);

  if (!data.ObjectId) return null;

  if (data.ImageDeny === true) {
    content = getDenyImage(control.VCX, data.Stretch, control.MetaData.Bounds.Align, control.ComputedMinHeight);
  } else if (data.IsSupported === false) {
    content = (
      <div className="previewNotSupported">
        <div style={{ flexDirection: "column", alignSelf: "center" }}>
          <K2TruncateText line={4} style={{ textAlign: "center" }}>{`${__("filePreviewText1")} "${data.ObjectId}" ${__("filePreviewText2")}`}</K2TruncateText>
        </div>
      </div>
    );
  } else {
    if (data.IsLoaded) {
      if (isOthersSupportedFile()) {
        content = <K2FileViewer url={data.Alias ? data.Alias : data.ObjectId} />;
      } else {
        if (control.Ncl.FrgtData.PanAllowed || control.Ncl.FrgtData.ZoomAllowed) {
          content = (
            <K2FullScreenViewer
              title={data.Title}
              asModal={false}
              zoomAllowed={control.Ncl.FrgtData.ZoomAllowed}
              panAllowed={control.Ncl.FrgtData.PanAllowed}
              fullScreenOption={true}
            >
              <K2Img glyphId={data.Alias ? data.Alias : data.ObjectId} vcx={control.VCX} />
            </K2FullScreenViewer>
          );
        } else {
          content = <K2Img glyphId={data.Alias ? data.Alias : data.ObjectId} vcx={control.VCX} />;
        }
      }
    }
  }

  return (
    <div ref={element} style={StyleHelper(control, props.style)} className={`${css.file} ${isZoomPanAsModal() ? "clickable" : ""}`} onClick={handleClick}>
      {content}
    </div>
  );
};

export default K2FilePreview;
