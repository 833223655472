import React from "react";
import { UFUpdateControl } from "../../common/communication.base";
import { NclPreviewPanel } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { GenerateChildrenOfControl } from "../K2GenerateControl";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2TruncateText from "../Text/K2TruncateText";
import css from "./PreviewPanel.scss";

const K2PreviewPanel = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclPreviewPanel, UFUpdateControl, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclPreviewPanel
  );

  return (
    <div
      ref={element}
      style={StyleHelper(control, {
        ...props.style,
        overflow: control.Ncl.FrgtData.Scroll ? "auto" : "hidden",
        // minHeight: control.ComputedMinHeightWithMargin + "px",
      })}
      className={css.pp}
    >
      <K2TruncateText className={css.pp_title}>{data.Title}</K2TruncateText>
      {GenerateChildrenOfControl(control)}
    </div>
  );
};

export default K2PreviewPanel;
