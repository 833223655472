import React from "react";
import { ApplicationBase } from "../app";
import { createRoot } from "react-dom/client";
import LoginView from "./Login/K2Login";
import { NclDockControl } from "../common/components.ncl";
import {
  TClientFileActionType,
  CSClientData,
  cClientUrlType,
  CSClientUrl,
  cClientFileType,
  CSClientSound,
  CSClientFile,
  cClientSoundType,
  cClientClipboardType,
  CSClientClipboard,
} from "../common/communication.base";
import { MediaManager, MediaData } from "../common/mediaManager";
import { Context, __ } from "../appcontext";
import { ViewRealizerManager } from "../viewrealizer";
import K2ErrorBoundary from "./K2ErrorBoundary";
import { copyToClipboard, Log, repairUrl } from "../common/common";
import { PointerEvents, AppContext, VCXContext } from "../context";
import { showClientDialog } from "./ClientDialog/K2ClientDialog";
import { withVCXInCSS, WithVCXinCSSProps } from "./VCX/VCXHelper";
import { K2PageHolder } from "./PageHolder/K2PageHolder";
import { FullScreenProvider } from "./FilePreview/K2FullScreenViewer";

interface AppProps extends WithVCXinCSSProps {
  container: HTMLElement;
  callback: () => void;
}

interface AppState {
  pointerEvents: PointerEvents;
}

class _App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = { pointerEvents: "auto" };
  }

  update = () => {
    this.forceUpdate(); // po expand/collapse expanderu je nutne provest re-render kvuli zmene vysek
  };

  updatePointerEvents = (event: PointerEvents) => {
    if (this.state.pointerEvents !== event) {
      this.setState({ pointerEvents: event });
    }
  };

  componentDidMount(): void {
    if (this.props.onVCXChanged) this.props.onVCXChanged(Context.getApplication()?.appViewRealizer?.VCX, this.props.container);
  }

  render() {
    return (
      <AppContext.Provider value={{ update: this.update, updatePointerEvents: this.updatePointerEvents, pointerEvents: this.state.pointerEvents }}>
        <div style={{ flex: "1 1 auto" }} ref={this.props.callback}>
          <K2PageHolder controlUID={this.props.controlUID} vrUID={this.props.vrUID} />
          <FullScreenProvider />
        </div>
      </AppContext.Provider>
    );
  }
}

const App = withVCXInCSS(_App);

export class K2App extends ApplicationBase {
  static readonly mainContentId = "maincontent";
  private dockControl: NclDockControl;
  private player: AudioPlayer;
  private container = document.getElementById(K2App.mainContentId);
  private root = createRoot(this.container);

  protected internalRender(): Promise<void> {
    super.internalRender();
    return new Promise((resolve, reject) => {
      if (this.container) {
        this.player = new AudioPlayer();
        this.dockControl = new NclDockControl(this.appViewRealizer.getDock(), null, null, null, this.appViewRealizer.getRealizerUID());

        this.root.render(
          <>
            <VCXContext.Provider value={{ vcx: this.appViewRealizer.VCX }}>
              <K2ErrorBoundary>
                <App
                  key={Date.now()}
                  controlUID={this.dockControl.MetaData.ControlUID}
                  vrUID={this.appViewRealizer.getRealizerUID()}
                  container={this.container}
                  callback={() => this.cb(resolve)}
                />
              </K2ErrorBoundary>
            </VCXContext.Provider>
          </>
        );
      } else {
        reject();
      }
    });
  }

  protected showError(errorMessage: string) {
    this.internalLoginForm(undefined, errorMessage);
  }

  protected updateLocalization(localizationVersion: number) {
    this.internalLoginForm(undefined, undefined, localizationVersion);
  }

  cb = async (resolve: () => void) => {
    if (this.dockControl) {
      await this.dockControl.dockViewRealizer(this.appViewRealizer.getRealizerUID());
    }
    resolve();
  };

  public getMainDockControl(): NclDockControl {
    return this.dockControl;
  }

  protected internalClear() {
    this.player = null;
    this.dockControl = null;
  }

  protected processClientData(list: Array<CSClientData>) {
    if (list && list.length > 0) {
      let data: CSClientData;
      for (let i = 0; i < list.length; i++) {
        data = list[i];
        if (data.__type === cClientUrlType) {
          this.openUrl((data as CSClientUrl).Url, (data as CSClientUrl).Url);
        }
        if (data.__type === cClientSoundType) {
          this.play(data as CSClientSound);
        }
        if (data.__type === cClientClipboardType) {
          this.copyToClipboard(data as CSClientClipboard);
        }
        if (data.__type === cClientFileType) {
          this.processFileData(data as CSClientFile);
        }
      }
    }
  }

  private processFileData(data: CSClientFile) {
    if (data.ErrorMessage) {
      if (data.Action != TClientFileActionType.catUnknown) {
        alert(data.ErrorMessage);
      } else {
        MediaManager.setErrorMessge(data.FileName, data.ErrorMessage, data.UseCache);
      }
      return;
    }
    if (!data.Url) alert(`Url for file ${data.FileName} is empty.`);

    if (data.Action === TClientFileActionType.catUnknown) {
      MediaManager.download(data.Url, data.FileName); // unknown action only downloaded data from server and hold it for future used.
    } else {
      if (data.UseCache) {
        MediaManager.get(data.Url, data.FileName).then((result) => {
          this.processFile(result, data.Action);
        });
      } else {
        this.processFile(data, data.Action);
      }
    }
  }

  private internalPrint(data: CSClientFile | MediaData) {
    const printerElement = "___PRINTER__FRAME___";
    let iframe = document.getElementById(printerElement) as HTMLIFrameElement;
    let exist = true;
    if (!iframe) {
      iframe = document.createElement("iframe");
      iframe.id = printerElement;
      exist = false;
    }

    iframe.style.display = "none";

    if (data instanceof MediaData) {
      iframe.src = URL.createObjectURL(data.blob);
    } else if (data.Url) {
      iframe.src = repairUrl(data.Url);
    } else {
      throw Error("Unknown data type for open");
    }

    if (exist == false) document.body.appendChild(iframe);
    iframe.contentWindow.focus();
    setTimeout(() => {
      iframe.contentWindow.print(); // Print.
    }, 350);
  }

  private processFile(data: CSClientFile | MediaData, type: TClientFileActionType) {
    switch (type) {
      case TClientFileActionType.catPrint:
        this.internalPrint(data);
        break;
      case TClientFileActionType.catDownload:
      case TClientFileActionType.catOpen:
        if (data instanceof MediaData) {
          const win = this.openWindow();
          win.document.write(
            '<iframe src="' +
              URL.createObjectURL(data.blob) +
              '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
          );
        } else if (data.Url) {
          let url = data.Url;
          if (data.UrlPattern) {
            url = data.UrlPattern.replace(data.FileName, data.Url);
          }
          this.openUrl(url, data.FileName);
        } else {
          throw Error("Unknown data type for open");
        }
        break;
      case TClientFileActionType.catDeviceConnector:
        if (!(data instanceof MediaData)) {
          Context.DeviceConnector.setFile(data);
        }
        break;
      default:
        break;
    }
  }

  private play(data: CSClientSound) {
    if (!data.Url) {
      Log.warn(`Can't play file, url for file ${data.FileName} is empty.`);
      return;
    }
    if (data.ErrorMessage) {
      if (data.UseCache) {
        MediaManager.setErrorMessge(data.FileName, data.ErrorMessage, data.UseCache);
      } else {
        alert(data.ErrorMessage);
      }
    }

    if (!this.isAndroidDelayedAction()) {
      if (data.UseCache) {
        MediaManager.get(data.Url, data.FileName).then((result) => {
          this.player.play(result);
        });
      } else {
        this.player.play(data.Url);
      }
    }
  }

  private copyToClipboard(data: CSClientClipboard) {
    copyToClipboard(data.Text);
  }

  private isAndroidDelayedAction(): boolean {
    if (Context.DeviceInfo.IsAndroidDevice) {
      if (new Date().getTime() - this.lastRequestStartTime > 4500) {
        return true;
      }
    }
    return false;
  }

  private openUrl(url: string, name: string) {
    if (url) {
      url = repairUrl(url);
      if (url.match(/^\w*:/i)) {
        if (!url.match(/^https?:\/\//i)) {
          if (this.isAndroidDelayedAction()) {
            // When time between user interact and open url is greather than 4,5s,  must open client window with button for fire url open
            showClientDialog(__("urlActionIsReady"), () => {
              this.openWindow(url, "__blank");
            });
            return;
          }
        }
        this.openWindow(url, name ? name : "__blank");
        return;
      } else if (!url.match(/^https?:\/\//i)) {
        url = "http://" + url;
      }

      this.openWindow(url, name);
    }
  }

  public openWindow(url?: string, target?: string, features?: string): Window {
    const old = this.hideReloadAlert;
    this.hideReloadAlert = true;
    const win = window.open(url, target, features);
    this.hideReloadAlert = old;
    return win;
  }

  protected loginForm(): void {
    this.internalLoginForm(undefined);
  }

  private internalLoginForm(reconnectFce: () => void, errorMessage: string = undefined, localizationVersion = 0): void {
    this.root.render(
      <LoginView
        error={errorMessage ? { message: errorMessage } : undefined}
        reconnect={reconnectFce ? { reconnectFce: reconnectFce } : undefined}
        headerImg={{ webm: "img/k2_iris_logo.webm", mp4: "img/k2_iris_logo.mp4" }}
        footerImg="img/logo_k2.svg"
        ver="K2 iris"
        ToSLink="https://www.k2.cz/"
        privacyPolicyLink="#"
        licenseHolder="K2 software s.r.o."
        localizationVersion={localizationVersion}
      />
    );
  }

  protected reconnectForm(reconnectFce: () => void): void {
    this.internalLoginForm(reconnectFce);
  }
}

class AudioPlayer {
  private element: HTMLAudioElement;
  private queue: Array<string | MediaData>;
  private isPlaying = false;
  private current: string | MediaData;

  public constructor() {
    this.element = new Audio();
    this.element.onended = this.handleEnded;
    this.queue = [];
  }

  private handleEnded = (e: Event) => {
    this.isPlaying = false;
    if (this.queue.length > 0) {
      this.internalPlay();
    }
  };

  play(data: string | MediaData) {
    this.queue.push(data);
    this.internalPlay();
  }

  internalPlay() {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.current = this.queue.pop();
      if (this.current instanceof MediaData) {
        this.element.src = this.current.base64Data;
      } else {
        this.element.src = this.current.toString();
      }
      this.element.play();
    }
  }
}

export function setRequestedActiveControl(e: React.FocusEvent<any>, vrUID: string) {
  const vr = ViewRealizerManager.getViewRealizer(vrUID);
  if (vr) {
    if (e.relatedTarget && e.relatedTarget instanceof HTMLElement) {
      let id = e.relatedTarget.getAttribute("data-k2-test-id");
      if (!id || id === "") {
        id = e.relatedTarget.id;
      }
      const ctrl = vr.findControlByName(id);
      if (ctrl) {
        vr.RequestActiveControlUID = ctrl.MetaData.ControlUID;
      }
    }
  }
}
