import { List } from "immutable";
import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { cClientFileType, CSFile, TOpenOption, UpdateControl } from "../../common/communication.base";
import { NclButton, NclOpenDialog } from "../../common/components.ncl";
import K2Button from "../Button/K2Button";
import { K2Header } from "../Expander/K2Expander";
import { useServerState } from "../hooks";
import { WithContextPlacementProps } from "../k2hoc";
import K2FileList from "./K2FileList";
import css from "./OpenDialog.scss";
import { __ } from "../../appcontext";
import { K2BusyIndicator } from "../BusyIndicator/K2BusyIndicator";
import K2Img from "../Image/K2Img";

interface FileItem extends CSFile {
  Id: number;
  Size: number;
}

const MAX_FILE_SIZE = 50000;

const K2OpenDialog = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclOpenDialog, UpdateControl, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclOpenDialog
  );
  const [index, setIndex] = useState(0);
  const [upData, setUpData] = useState(List<FileItem>());
  const [draggingCount, setDraggingCount] = useState<number>(0);
  const input = useRef<HTMLInputElement>();
  const filesBuffer = useRef<FileList>();
  const fileUIDCounter = useRef(0);
  const allowMultipleSelect = (control.Ncl.FrgtData.Options & TOpenOption.ofAllowMultiSelect) == TOpenOption.ofAllowMultiSelect;
  let fileReader: FileReader;

  const setDragging = (dragging: boolean) => {
    if (dragging) setDraggingCount((value) => value + 1);
    else setDraggingCount((value) => (value - 1 < 0 ? 0 : value - 1));
  };

  const loading = (loading: boolean) => {
    if (loading) {
      document.body.style.cursor = "wait";
      if (element.current) element.current.setAttribute("data-loading", "1");
    } else {
      document.body.style.cursor = "default";
      if (element.current) element.current.setAttribute("data-loading", "0");
    }
  };

  const computeAllowType = () => {
    let fileAllowType = "";
    let fileAccessFilter = "";
    let all = false;
    const filter = control.Ncl.FrgtData.Filter;

    if (filter.toString().match("\\*\\.\\*")) {
      fileAccessFilter = "";
      all = true;
    }

    const regex = new RegExp("\\*\\.\\w+|\\*\\.\\*", "g");
    const matches = filter.toString().match(regex);

    if (matches) {
      if (!all) {
        fileAccessFilter = matches.join(",");
      }

      fileAllowType = matches.join(",").replace(new RegExp("\\*\\.\\*", "g"), "").replace(new RegExp("\\*", "g"), "");
    }

    return { fileAllowType: fileAllowType, fileAccessFilter: fileAccessFilter };
  };

  const checkFilesTypeBeforeUpdate = (name: string) => {
    const fileAccessFilter = computeAllowType().fileAccessFilter;
    const type: string = name.split(".")[name.split(".").length - 1].toUpperCase();
    let allowFileResult: boolean = !fileAccessFilter || fileAccessFilter == "" ? true : false;

    if (!allowFileResult) {
      const allowFileTypeArray: Array<string> = fileAccessFilter.split(",");

      if (type.length) {
        allowFileTypeArray.map((item) => {
          if (item.toUpperCase().endsWith(type)) {
            allowFileResult = true;
          }
        });
      }
    }

    return allowFileResult;
  };

  const clearSelectedFiles = () => {
    if (allowMultipleSelect) {
      setIndex(0);
    } else {
      setUpData(List<FileItem>());
      setIndex(0);
    }
    filesBuffer.current = null;
  };

  const beforeOKExecute = (ctrl: NclButton) => {
    control.acceptFiles(upData.toArray());
  };

  const handleRemoveFile = (index: number) => {
    let newData = List<FileItem>();

    if (index > 0 && upData) {
      upData
        .filter((item) => item.Id != index)
        .map((file: FileItem) => {
          newData = newData.push(file);
        });
    }
    setUpData(newData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearSelectedFiles();

    if (e.target.files.length > 0) {
      filesBuffer.current = input.current.files;
      readNextFile();
    }
  };

  const readNextFile = () => {
    loading(true);

    if (filesBuffer.current.length > index) {
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsArrayBuffer(filesBuffer.current[index]);
    }
  };

  const handleFileRead = (e: Event) => {
    if (!filesBuffer.current && filesBuffer.current.length >= index) return;

    if (!allowMultipleSelect && upData.count() >= 1) {
      loading(false);
      filesBuffer.current = null;

      return;
    }

    const newIndex = index + 1;

    if (filesBuffer.current[index].size / 1024 > MAX_FILE_SIZE) {
      alert(__("fileTooBig"));
      loading(false);
      setIndex(newIndex);

      return;
    }

    if (filesBuffer.current[index]) {
      if (checkFilesTypeBeforeUpdate(filesBuffer.current[index].name)) {
        if (filesBuffer.current[index]) {
          fileUIDCounter.current++;
          const newData: List<FileItem> = upData.push({
            Id: fileUIDCounter.current,
            FileName: filesBuffer.current[index].name,
            Data: getFileData(),
            Size: filesBuffer.current[index].size,
            __type: cClientFileType,
          });
          setIndex(newIndex);
          setUpData(newData);
        }
      } else {
        setIndex(newIndex);
        // nemělo by zde být -1?
      }
    }
  };

  const getFileData = () => {
    const data = fileReader.result;

    if (data instanceof ArrayBuffer) {
      return btoa(
        new Uint8Array(data).reduce(function (data, byte) {
          return data + String.fromCharCode(byte);
        }, "")
      );
    } else {
      return data;
    }
  };

  const dragenterListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);

    if (event.dataTransfer.items && event.dataTransfer.items[0]) {
      setDragging(true);
    }
  };

  const dragleaveListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    setDragging(false);
  };

  const dropListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    setDragging(false);

    if (event.dataTransfer.files) {
      clearSelectedFiles();
      filesBuffer.current = event.dataTransfer.files;
    }
  };

  const overrideEventDefaults = (event: Event | React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  if (filesBuffer.current && index < filesBuffer.current.length) {
    readNextFile();
  } else {
    loading(false);
    if (input.current) {
      //čistím input abych mohl opětovně vybrat stejný soubor a došlo tak k odpálění onChange
      input.current.value = "";
    }
  }

  return (
    <div className={css.od} tabIndex={-1} ref={element}>
      {control.isLite() && (
        <K2Header controlUID={control.Header.MetaData.ControlUID} vrUID={control.getRealizerUID()} className={"handle_" + control.MetaData.ControlUID} />
      )}
      <div
        className={css.od_body}
        onDrag={overrideEventDefaults}
        onDragStart={overrideEventDefaults}
        onDragEnd={overrideEventDefaults}
        onDragOver={overrideEventDefaults}
        onDragEnter={dragenterListener}
        onDragLeave={dragleaveListener}
        onDrop={dropListener}
      >
        <div className={`${css.od_drop_area}${draggingCount > 0 ? ` ${css.od_drop_area_dragging}` : ""}`}>
          <p className={`mouse_only ${css.od_text}`}>{`${__("fileDropText")}`}</p>
          <p className={`mouse_only ${css.od_text}`}>{__("fileDropOr")}</p>
          <label htmlFor="open_dialog_input_file" id={"filesUp_select_file_label"} className={`button ${css.od_input}`}>{`${__("fileSelect")} ${__(
            "fileOnDrive"
          )}`}</label>
          <input
            type="file"
            id="open_dialog_input_file"
            multiple={allowMultipleSelect}
            onChange={handleChange}
            ref={input}
            style={{ display: "none" }}
            accept={computeAllowType().fileAllowType}
          />
          <div className="loading_wrap">
            <K2BusyIndicator visibility={true} backgroundShadow={false}>
              <K2Img glyphId={"wui*tax.document.set"} width={30} height={30} />
            </K2BusyIndicator>
          </div>
        </div>
        <K2FileList items={upData} handleRemoveFile={handleRemoveFile} allowMultipleSelect={allowMultipleSelect} vcx={control.VCX} />
        <div className={css.od_buttons}>
          {control.OK && upData.size > 0 && (
            <K2Button
              controlUID={control.OK.MetaData.ControlUID}
              vrUID={props.vrUID}
              beforeExecute={beforeOKExecute}
              style={{ minWidth: `${control.VCX.sizeMap(150)}px` }}
            />
          )}
          {control.Cancel && (
            <K2Button controlUID={control.Cancel.MetaData.ControlUID} vrUID={props.vrUID} style={{ minWidth: `${control.VCX.sizeMap(70)}px` }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default K2OpenDialog;
